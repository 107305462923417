import { theme } from "../../theme/theme";
import { V3IconInfo } from "../Icons";
import { V3CaptionGrey35 } from "../ReusableStyledComponents/V3CaptionGrey35";
import { VerticalSpacing } from "../VerticalSpacing";
import { CBDWrapper } from "./BannerDetails.styles";

export const BannerDetails = ({ children }: React.PropsWithChildren) => {
    return (
        <VerticalSpacing bottom={12} top={12}>
            <CBDWrapper>
                <div>
                    <V3IconInfo stroke={theme.v3.colors.midBlue} />
                </div>
                <div>
                    <V3CaptionGrey35>{children}</V3CaptionGrey35>
                </div>
            </CBDWrapper>
        </VerticalSpacing>
    );
};
