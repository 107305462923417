import styled from "styled-components";
import { media } from "../../theme/theme";

export const DropdownHRElemt = styled.div`
  ${media.desktop} {
    padding: 0 12px;
  }

  hr {
    margin-bottom: 12px;
    border-top: ${({ theme }) => `1px solid ${theme.v3.colors.grey88}`};
  }
`;
