import { ArticlePreviewResponse, ContentResponse } from "authory-api-types/dist/types";
import { useQueryClient } from "@tanstack/react-query";
import { UpdateArticlePayload } from "authory-api-types/dist/types/content";
import { useRouter } from "next/router";
import { useContext } from "react";
import { CookieContext } from "../../context/cookieContext";
import { getAuthenticatedUserClient } from "../../utils/getAuthenticatedUser";
import { UserCoverStyle } from "authory-api-types/dist/enums";
import { getUpdateArticleMutation } from "../../utils/mutation-utils/content/updateArticleMutation";
import { NotificationTargets } from "../../types/notification";
import { getUploadTemporaryImageMutation } from "../../utils/mutation-utils/upload-image/getUploadTemporaryImageMutation";
import { V3ModalBase } from "../V3ModalBase";
import { getDescription, getImage, getTitle, SEO_TYPE } from "../OGTags";
import { ItemPreviewEdit, ItemPreviewFormik } from "../ItemPreviewEdit";
import { V3BodyGrey35 } from "../ReusableStyledComponents/V3BodyGrey35";
import { InlineLink } from "../ReusableStyledComponents/InlineLink";

interface V3EditContentSEOArticleContainerProps {
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
    article: ContentResponse | ArticlePreviewResponse;
}

export const V3EditContentSEOArticleContainer = ({ modalOpen, setModalOpen, article }: V3EditContentSEOArticleContainerProps) => {
    const router = useRouter();
    const token = useContext(CookieContext);
    const queryClient = useQueryClient();
    const { authenticatedUser } = getAuthenticatedUserClient(token, router, true);

    // Update article mutation
    const updateArticleMutation = getUpdateArticleMutation(queryClient, token!);

    const title = getTitle(SEO_TYPE.ARTICLE, authenticatedUser.data!, article);
    const description = getDescription(SEO_TYPE.ARTICLE, authenticatedUser.data!, article);

    // Upload temporary image
    const uploadTemporaryImageMutation = getUploadTemporaryImageMutation();

    const onTemporaryImageUploadHandler = async (file: File) => {
        if (!authenticatedUser.data?.slug || !token) return "";

        const res = await uploadTemporaryImageMutation.mutateAsync({
            file: file,
            token,
            userSlug: authenticatedUser.data?.slug
        });

        return res.url;
    };

    const onItemPreviewSubmit = async (data: ItemPreviewFormik) => {
        if (!token || !authenticatedUser.data || !article.canonicalSlug) return;

        const params: UpdateArticlePayload = {};

        // null = preview returns to api determined preview
        // if this is true, user wants to reset the preview entirely
        if (data.resetPreview) {
            params.customThumbnailImage = null;
            params.ogTitle = null;
            params.ogDescription = null;
        } else {
            params.date = data.date;

            if (data.imageUpdated) {
                if (data.style === UserCoverStyle.image) {
                    params.customThumbnailImage = data.customImage;
                } else if (data.style === UserCoverStyle.none) {
                    params.customThumbnailImage = UserCoverStyle.none;
                }
            }

            if (data.title && data.title !== title) params.ogTitle = data.title;
            if (data.description && data.description !== description) params.ogDescription = data.description;
        }

        await updateArticleMutation.mutateAsync({
            token,
            userSlug: authenticatedUser.data?.slug,
            data: params,
            articleSlug: article.canonicalSlug,
            target: NotificationTargets.SEO
        });

        setModalOpen(false);
    };

    return (
        <V3ModalBase
            isOpen={modalOpen}
            onClose={() => {
                setModalOpen(false);
            }}
            width="850px"
            useHalfContentSplit
            title="Edit preview"
        >
            <ItemPreviewEdit
                mainParagraph={
                    <V3BodyGrey35>
                        Preview of what the content will look like on your portfolio, in collections and when shared on social media.{" "}
                        <InlineLink href="https://authory.com/help/en/articles/9667500-how-does-the-preview-of-content-items-work" target="_blank">
                            Learn more
                        </InlineLink>
                    </V3BodyGrey35>
                }
                title={title}
                hidePreviewLabel
                description={description}
                date={article.date || ""}
                sourceSlug={(typeof article.source === "object" ? article.source.publicationName : article?.sourceName) || ""}
                onNextText={"Save"}
                sourcesOptions={[]}
                name={(typeof article.source === "object" ? article.source.publicationName : article?.sourceName) || ""}
                isAddContentFlow={false}
                previewImage={getImage(SEO_TYPE.ARTICLE, authenticatedUser.data!, article)}
                contentType={article.type}
                style={article.thumbnailStyle}
                onTemporaryImageUploadHandler={onTemporaryImageUploadHandler}
                onItemPreviewSubmit={onItemPreviewSubmit}
            />
        </V3ModalBase>
    );
};
