import { QueryClient, useMutation } from "@tanstack/react-query";
import { updateArticle } from "../../../api/articles";
import { onArticleOptimisticUpdate } from "./onArticleOptimisticUpdate";
import { ContentResponse, ContentWithSharesResponse } from "authory-api-types/dist/types";
import { AxiosError } from "axios";
import { Queries, getItemQuerie } from "../../../types/queries";
import { V3GenericErrorHandler } from "../../../api/error-handler";
import toast from "react-hot-toast";
import { NotificationTargets } from "../../../types/notification";
import { assign } from "lodash";

// Update collection mutation
export const getUpdateArticleMutation = (
    queryClient: QueryClient,
    token: string,
) => useMutation({
    mutationFn: updateArticle,
    onMutate: ({ data, articleSlug }) => {
        return onArticleOptimisticUpdate(queryClient, token, articleSlug, data as unknown as ContentResponse);
    },
    onError: (err: AxiosError, _, oldData) => {
        queryClient.setQueryData(getItemQuerie(token, oldData?.article.canonicalSlug!), oldData);
        V3GenericErrorHandler(toast.error)(err);
    },
    onSuccess: (data, variables) => {

        queryClient.setQueryData<ContentWithSharesResponse | undefined>(getItemQuerie(token, variables.articleSlug), (item) => {
            if (!item) return item;
            const newArticle = assign({}, item.article, data);
            return assign({}, item, { article: newArticle });
        });

        queryClient.invalidateQueries({ queryKey: [Queries.Collections] });
        queryClient.invalidateQueries({ queryKey: [Queries.Content] });
        queryClient.invalidateQueries({ queryKey: [Queries.AuthorItem] });
        queryClient.invalidateQueries({ queryKey: [Queries.ContentAnalytics] });
        queryClient.invalidateQueries({ queryKey: [Queries.Newsletter] });

        if (variables.target === NotificationTargets.SEO) {
            toast.success("Preview updated");
        } else if (variables.target === NotificationTargets.ContentLinkSettingsUpdated) {
            toast.success("Link settings updated");
        } else if (variables.target === NotificationTargets.ContentEdited) {
            toast.success("Content edited");
        } else if (variables.target === NotificationTargets.SearchEngineSettingsUpdated) {
            toast.success("Search Engine settings updated");
        }
    }
});