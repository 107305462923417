import { ContentType } from "authory-api-types/dist/enums";
import { ArticleFilterQueryParams, GeneralContentOrderBy } from "authory-api-types/dist/types";
import { ParsedUrlQuery } from "querystring";
import { NORMAL_RESULT_LIMIT } from "../../../types/pagination";
import { isNil, omitBy } from "lodash";
import { SearchContentFormik } from "../../../types/content-search";

export const buildContentFilterQueryParams = (
    data: ParsedUrlQuery | SearchContentFormik,
    includePinned: boolean = false,
    customResultLimit?: number,
    isCollection = false
) => {
    const contentFilterQueryParams = new ArticleFilterQueryParams();

    contentFilterQueryParams.take = customResultLimit || NORMAL_RESULT_LIMIT;

    contentFilterQueryParams.sources = typeof data.source === "string" ? data.source.split(",") : data.source;

    contentFilterQueryParams.type = typeof data.type === "string" ? (data.type.split(",") as ContentType[]) : (data.type as ContentType[]);

    // special handling of combined content types
    if (!!data.type) {
        contentFilterQueryParams.type = contentFilterQueryParams.type.reduce(
            (acc, val) => [...acc, ...val.split("$")],
            [] as string[]
        ) as ContentType[];
    }

    if (typeof data.from === "string") contentFilterQueryParams.from = data.from;
    if (typeof data.to === "string") contentFilterQueryParams.to = data.to;
    if ("collection" in data && typeof data.collection === "string") contentFilterQueryParams.collection = data.collection;

    if (typeof data.nodate === "string" && data.nodate === "true") contentFilterQueryParams.nodate = true;
    contentFilterQueryParams.subaccounts = typeof data.subAccount === "string" ? data.subAccount.split(",") : data.subAccount;

    if (typeof data.sort === "string") contentFilterQueryParams.orderby = data.sort as GeneralContentOrderBy;
    else if (!isCollection) contentFilterQueryParams.orderby = "date_DESC";

    if (typeof data.text === "string") {
        contentFilterQueryParams.text = data.text;
        if (typeof data.sort === undefined) contentFilterQueryParams.orderby = "relevance_DESC";
    }

    // We only request pinned articles if:
    // -> no other filters are set (other than collection)
    // -> we're on first page
    if (includePinned && !data.source && !data.from && !data.to && !data.nodate && !data.text) {
        contentFilterQueryParams.includePinned = true;
    }

    // remove all unset values. We want to use objects with only good values because this object is used by react-query for cache key
    // in order to populate correctly the values between SSR fetched content and frontend (hydration), we need to use clean objects, without null or undefined values.
    return omitBy(contentFilterQueryParams, isNil);
};
